import routes from "../router";

function matchRoute(path) {
    const findPath = routes.find((val) => {
        return val.path === path});
    if (!findPath) {
        return;
    }
    return findPath;
}
export default matchRoute;