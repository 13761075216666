import React, { createContext, useContext, useRef } from "react";
import $reducer from "../../utils/app/$reducer";
import $fetch from "../../utils/app/$fetch";
import { Toast } from "antd-mobile";

const LoginContext: any = createContext(undefined);
export const useLogin = () => useContext(LoginContext);

export const LoginProvider = ({ children }: any) => {
  const refTimer = useRef<any>(null);
  const initState: any = {
    telephone: "",
    authCode: "",
    isReaded: false,
    activeCode: false,
    activeBtn: false,
    btnText: "获取验证码",
    verificationSeqNo: "",
    serviceVisible: false,
    privacyVisible: false,
    showName: false,
    showAvatar: false,
    showCropper: false,
    avatar: [{ url: "" }],
    image: '',
    img: '',
  };
  
  const [state, dispatch] = $reducer({ ...initState });
  const fetchLogin = async () => {
    try {
      const telephone = state?.telephone?.trim();
      if (!telephone) {
        Toast.show("请先输入手机号");
        return;
      }

      if (!/^[1][3-9][0-9]{9}$/.test(telephone)) {
        Toast.show("手机号格式有误");
        return;
      }

      if (!state?.authCode) {
        Toast.show("验证码不能为空");
        return;
      }

      if (!state?.isReaded) {
        Toast.show("请阅读并同意《用户协议》和《隐私政策》！");
        return;
      }

      const res: any = await $fetch(
        `customer/login?authCode=${state?.authCode}&telephone=${state?.telephone}`,
        {},
        { method: "post", noGroupId: "no" }
      );
      res?.token && localStorage.setItem("token", res.token);
      if (res?.RoleName === "前台运营") {
        window.location.replace("/#/activity/list");
      } else if (res?.RoleName === "前台用户") {
        if (res?.isFirstLogin === "0") {
          // 初次登录
          dispatch({ showName: true });
        } else if (res?.isFirstLogin === "1") {
          window.location.replace("/#/home");
        }
        // dispatch({ showName: true });
      }
    } catch (error) {
      dispatch({
        btnText: '获取验证码',
        activeCode: true,
      });
      clearTimeout(refTimer.current);
      refTimer.current = null;
      console.log(error, "____error");
    }
  };
  return (
    <LoginContext.Provider value={{ state, dispatch, fetchLogin, refTimer }}>
      {children}
    </LoginContext.Provider>
  );
};
