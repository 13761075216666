import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import matchRouter from "./matchRouter";
import $getQuery from "../utils/app/$getQuery";

export default function AuthRouter({ children }) {
  const { groupId } = $getQuery();
  const location = useLocation();
  const token = localStorage.getItem("token");
  const isLogin = token && token !== "undefined";
  const currentPath = location.pathname;
  console.log(currentPath, "______currentPath");

  // Define route conditions
  const isPublicRoute = ["/login", "/home", "/404"].includes(currentPath);

  const findPath = matchRouter(currentPath);

  // Handle public routes
  if (isPublicRoute) {
    if (!isLogin && currentPath === "/home") {
      return <Navigate to={`/login?groupId=${groupId}`} />;
    }
    if (isLogin && currentPath === "/login") {
      return <Navigate to="/home" />;
    }
    return children;
  }

  // Handle protected routes
  if (!isLogin) {
    return <Navigate to="/login" />;
  }

  if (isLogin && !findPath) {
    return <Navigate to="/404" />;
  }

  // Render children for valid paths
  return children;
}
